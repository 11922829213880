import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as AppActions from '../../AppActions';
import {Button, message} from 'antd';
import {Close} from '@styled-icons/evil/Close';
import {isInPreview} from '../../Utils/PreviewUtil';

const THEME_COLOR = '#d2b48c';

function CartItem(props) {
  const {index, item} = props;
  return (
    <CartItemWrapper>
      <img
        src={item.product.images[0]?.expected_url || '/images/bakey.png'}
        alt="img url"
      />
      <div className="info">
        <div className="name">{item.product.name}</div>
        {item.config.variants.map((v, idx) => (
          <div key={idx} className="variant">
            {v.choice || v.name}
          </div>
        ))}
        <div className="quantity">x {item.config.qty}</div>

        {item.config.extra_data?.stock_chosen_date && (
          <div className="chosen-date">
            訂購日期:{item.config.extra_data.stock_chosen_date}
          </div>
        )}
        <div style={{flex: 1}} />
        <div className="amount">$ {item.amount}</div>
      </div>
      <div className="action">
        <Button
          onClick={async () => {
            AppActions.setLoading(true);
            try {
              await Cart.removeFromCart(index);
            } catch (ex) {
              message.error('發生錯誤, 請稍後重新嘗試');
            }
            AppActions.setLoading(false);
          }}>
          刪除
        </Button>
      </div>
    </CartItemWrapper>
  );
}

const CartItemWrapper = styled.div`
  --cart-item-height: 150px;
  --cart-item-img-ratio: 1;
  width: 100%;
  height: var(--cart-item-height);
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  & > img {
    height: 100%;
    aspect-ratio: var(--cart-item-img-ratio);
  }
  & > .info {
    width: 100%;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    & > .name {
      line-height: 1.57;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    & > .quantity {
    }
    & > .amount {
    }
  }
`;

function QuickCart(props) {
  const [show, setShow] = React.useState(false);
  const [store] = useOutlet('store');
  const [cart] = useOutlet('cart');
  const [actions] = useOutlet('actions');
  const _onCheckoutBtnClick = () => {
    actions.navigate('/checkout/info', {loading: 300});
  };

  const isCartEmpty = cart.items.length === 0;

  return (
    <>
      <ToggleBtnWrapper show={show}>
        <a
          href="/#"
          className="expand"
          onClick={(evt) => {
            evt.preventDefault();
            if (!isInPreview()) {
              setShow(true);
            }
          }}>
          <h2 className="highlight" style={{flex: 1}}>
            消費總計:
          </h2>
          <h2 className="highlight" style={{marginRight: 10}}>
            ${cart.subtotal || '0'}
          </h2>
        </a>
      </ToggleBtnWrapper>

      <Backdrop show={show} onClick={() => setShow(false)} />

      <SlideUpCart show={show}>
        <div className="content">
          <div
            className="header"
            style={{display: 'flex', alignItems: 'center'}}>
            <h2 className="highlight" style={{flex: 1}}>
              我的購物車
            </h2>
            <Button onClick={() => setShow(false)} type="text">
              <Close color="black" size={24} />
            </Button>
          </div>

          <div className="items-wrapper">
            {cart.items.map((item, index) => {
              return <CartItem key={index} item={item} index={index} />;
            })}

            {isCartEmpty && (
              <EmptyCart>
                <img src="/images/bakey.png" alt="bakey" />
              </EmptyCart>
            )}

            {!isCartEmpty && (
              <div
                style={{
                  backgroundColor: 'black',
                  height: 1,
                  margin: '10px 0',
                }}
              />
            )}

            {!isCartEmpty && (
              <div
                className="fade-in"
                style={{display: 'flex', alignItems: 'center'}}>
                <h2 className="highlight" style={{flex: 1}}>
                  消費總計:
                </h2>
                <h2 className="highlight" style={{marginRight: 10}}>
                  ${cart.subtotal || '0'}
                </h2>
                <Button
                  type="primary"
                  size="large"
                  onClick={() =>
                    actions.navigate('/checkout/info', {loading: 300})
                  }>
                  前往結帳
                </Button>
              </div>
            )}
          </div>
        </div>
      </SlideUpCart>
    </>
  );
}

const ToggleBtnWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 20px;

  & a.expand {
    display: flex;
    align-items: center;
    margin: 0 auto;
    max-width: 500px;
    background-color: ${THEME_COLOR};
    color: white;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    &:hover {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    }
    &:active {
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    }

    & .highlight {
      font-size: 1.5rem;
      font-weight: 150;
    }
  }
`;

const Backdrop = styled.div`
  z-index: 2;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  backdrop-filter: ${(props) => (props.show ? 'blur(5px)' : 'none')};
  background-color: ${(props) =>
    props.show ? 'rgba(0,0,0,0.2)' : 'transparent'};
  pointer-events: ${(props) => (props.show ? 'auto' : 'none')};
  transition: 200ms;
`;

const SlideUpCart = styled.div`
  --header-height: 70px;
  --border-radius: 32px;
  z-index: 2;
  position: fixed;
  top: ${(props) => (props.show ? '10vh' : '100vh')};
  left: 0px;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  transition: 300ms ease-in-out;
  padding: 0px 20px;
  overflow: hidden;

  & .fade-in {
    opacity: ${(props) => (props.show ? '1' : '0')};
    transition: 300ms 300ms ease-in-out;
  }

  & > .content {
    margin: 0 auto;
    max-width: 600px;
    background-color: white;
    /* padding: 20px; */
    height: ${(props) => (props.show ? '90vh' : 0)};
    /* overflow: ${(props) => (props.show ? 'auto' : 'hidden')}; */
    transition: 300ms ease-in-out;
    pointer-events: auto;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.6);
    border-top-right-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);

    display: flex;
    flex-direction: column;
    position: relative;

    & .header {
      position: absolute;
      width: 100%;
      height: var(--header-height);
      display: flex;
      align-items: center;
      background-color: white;
      border-top-right-radius: var(--border-radius);
      border-top-left-radius: var(--border-radius);
      padding: 0px 20px;
      z-index: 1;
    }

    & .items-wrapper {
      overflow: ${(props) => (props.show ? 'auto' : 'hidden')};
      padding: var(--header-height) 20px 20px;
    }

    & .highlight {
      font-size: 1.5rem;
      font-weight: 150;
    }
  }
`;

const EmptyCart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > img {
    width: 150px;
    height: 150px;
    object-fit: center;
    margin: 20px;
  }

  &::after {
    content: '您的購物車為空!';
    font-size: 1.2rem;
  }
`;

export default QuickCart;
