import React from 'react';
import styled from 'styled-components';
import {message} from 'antd';
import * as StockUtil from '../../Utils/StockUtil';

function toLocalDateStr(date) {
  return `${date.getFullYear()}-${('00' + ((date.getMonth() + 1) % 12)).slice(
    -2,
  )}-${('00' + date.getDate()).slice(-2)}`;
}

function ProductStock(props, ref) {
  const {product} = props;
  const [startDate, endDate] = StockUtil.getStartEndDate(product);
  const [selectedDate, setSelectedDate] = React.useState(
    (startDate && toLocalDateStr(startDate)) || null,
  );

  if (ref) {
    ref.current = {
      getSelectedDate: () => {
        return selectedDate;
      },
    };
  }

  // mobile safari doesn't support input[type='date'] min and max attrs,
  // so we have to manually check for date range here
  function onDateInputChange(evt) {
    const dateStr = evt.target.value;
    if (dateStr) {
      const dt = new Date(`${dateStr}T00:00`);
      if (
        startDate.getTime() <= dt.getTime() &&
        dt.getTime() <= endDate.getTime()
      ) {
        setSelectedDate(dateStr);
      } else {
        message.info('所選日期不在庫存區間內');
      }
    }
  }

  if (!product.stock_type || product.stock_type === 'always') {
    return null;
  }

  if (product.stock_type === 'total') {
    return (
      <Wrapper>
        <div className="field">
          <label>已售出</label>
          <div className="value">{product.stock_sold_amount}</div>
        </div>
      </Wrapper>
    );
  }

  if (!startDate || !endDate) {
    return null;
  }

  return (
    <Wrapper>
      <div className="field">
        <label>供應開始時間: </label>
        <div>{`${startDate.toLocaleString()}`}</div>
      </div>

      <div className="field">
        <label>供應結束時間: </label>
        <div>{`${endDate.toLocaleString()}`}</div>
      </div>

      <div className="field">
        <label>已售出</label>
        <div className="value">{product.stock_sold_amount}</div>
      </div>

      <div className="field">
        <label>訂購日期</label>
        <input
          type="date"
          name="rev-order-date-input"
          value={selectedDate}
          onChange={onDateInputChange}
          min={toLocalDateStr(startDate)}
          max={toLocalDateStr(endDate)}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & > .field {
    width: 260px;

    & > label {
      display: block;
    }

    & > .value {
      font-size: 1.2rem;
    }

    & > input[type='date'] {
      display: block;
      font-size: 1.2rem;
      padding: 6px 12px;
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
`;

export default React.forwardRef(ProductStock);
