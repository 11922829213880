import {useOutlet, useOutletSetter} from 'reconnect.js';
import React, {useCallback, useEffect} from 'react';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import {Button, message, Tabs} from 'antd';
import {Cross} from '@styled-icons/entypo/Cross';
import Carousel from '../../Components/Carousel';
import FixedRatioImage from '../../Components/FixedRatioImage';
import {mapLineBreak} from '../../Utils/TextUtil';
import ProductVariants from 'rev.sdk.js/Components/ProductVariants';
import styled from 'styled-components';
import {isInPreview} from '../../Utils/PreviewUtil';
import ProductStock from './ProductStock';
import * as StockUtil from '../../Utils/StockUtil';
import * as Constants from '../../constants';

function isExceedMaxQtyPerCheckout({product, cart, qty}) {
  const totalItemQty = (cart.items || []).reduce((acc, item) => {
    if (item.product.id.$oid === product.id) {
      return acc + item.config.qty;
    }
    return acc;
  }, qty);

  console.log('DBG', totalItemQty);

  if (product.max_qty_per_checkout) {
    return totalItemQty > product.max_qty_per_checkout;
  }

  return false;
}

const ProductDetailModal = ({product: _product, hideModal}) => {
  const [cart] = useOutlet('cart');
  const [user] = useOutlet('user');
  const [imgIdx, setImgIdx] = React.useState(0);
  const showLoginModal = useOutletSetter('login-modal');
  const [dimension] = useOutlet('dimension');
  const [activeSummaryTab, setActiveSummaryTab] = React.useState('intro');
  const [product, setProduct] = React.useState(null);
  const [article, setArticle] = React.useState(null);
  const [currItemConfig, setCurrItemConfig] = React.useState(null);
  const [currPrice, setCurrPrice] = React.useState(null);
  const stockCompRef = React.useRef();
  const productId = _product?.id;
  const productArticleId = _product?.article;

  useEffect(() => {
    async function fetchData() {
      try {
        AppActions.setLoading(true);
        setImgIdx(0);
        setProduct(await JStorage.fetchOneDocument('product', {id: productId}));
        if (productArticleId) {
          setArticle(
            await JStorage.fetchOneDocument('Article_Default', {
              id: productArticleId,
            }),
          );
        }
      } catch (ex) {
        console.warn(ex);
      } finally {
        AppActions.setLoading(false);
      }
    }

    if (productId) {
      fetchData();
    } else {
      setProduct(null);
      setArticle(null);
    }
  }, [productId, productArticleId]);

  const onNextConfig = useCallback((nextItemConfig, calcResp) => {
    setCurrPrice(calcResp.amount);
    setCurrItemConfig(nextItemConfig);
  }, []);

  const addToCart = async () => {
    if (isInPreview()) {
      return;
    }

    const itemConfig = {...currItemConfig};

    if (product.stock_type === 'period') {
      itemConfig.extraConfigs = {
        stock_chosen_date: stockCompRef.current.getSelectedDate(),
      };
    }

    const maxQty = StockUtil.getMaxQty(product);
    if (itemConfig.qty > maxQty) {
      message.info(`存貨剩下${maxQty}, 請調整您的購買數量!`);
      return;
    }

    if (isExceedMaxQtyPerCheckout({product, cart, qty: itemConfig.qty})) {
      message.info(
        `本商品一單最多購買${product.max_qty_per_checkout}件, 請檢查您的購物車是否有重複購買, 再調整您的購買數量!`,
      );
      return;
    }

    if (!user) {
      showLoginModal(true);
      return;
    }

    try {
      AppActions.setLoading(true);
      await Cart.addToCart(product.id, itemConfig);
      hideModal();
      message.success('成功');
    } catch (ex) {
      console.warn(ex);
      message.error('發生錯誤, 請稍後再重新嘗試');
    } finally {
      AppActions.setLoading(false);
    }
  };

  let gallerySize = 450;
  if (dimension.innerWidth) {
    if (dimension.innerWidth < 450 + 20 * 2) {
      gallerySize = dimension.innerWidth - 20 * 2;
    }
  }

  const {isOnSale, label: addToCartLabel} = product
    ? StockUtil.getOnSaleInfo(product)
    : {
        isOnSale: false,
        label: '---',
      };

  return (
    <StyledProductDetailModal visible={!!product}>
      <div className="cross" onClick={() => hideModal()}>
        <Cross size={36} color="#000" />
      </div>
      {product && (
        <div className="content">
          <TopSection>
            <Gallery dimension={dimension} size={gallerySize}>
              {product.images && product.images[imgIdx] && (
                <Carousel
                  currIdxFromParent={imgIdx}
                  width={gallerySize}
                  height={gallerySize}
                  data={product.images.map((i) => i.expected_url)}
                  renderPrev={null}
                  renderNext={null}
                  renderDots={null}
                  renderItem={({item}) => {
                    return (
                      <FixedRatioImage
                        image={item}
                        width="100%"
                        ratio={1}
                        mode="cover"
                        alt="product"
                      />
                    );
                  }}
                  disableSwipe
                />
              )}

              {product.images && (
                <MiniImageList>
                  {product.images.map((image, idx) => (
                    <MiniImageItem
                      src={image.expected_url}
                      alt="mini"
                      key={idx}
                      selected={idx === imgIdx}
                      onClick={() => setImgIdx(idx)}
                    />
                  ))}
                </MiniImageList>
              )}
            </Gallery>

            <div style={{flexBasis: 20}} />

            <Summary>
              <div className="title">
                <h2>{product.name}</h2>
                <div className="logi-label">
                  {
                    {normal: '常溫', fridge: '冷藏', freeze: '冷凍'}[
                      product.logistics_subtype || 'normal'
                    ]
                  }
                </div>
              </div>

              {!StockUtil.isSoldOut(product) ? (
                <>
                  <ProductStock product={product} ref={stockCompRef} />
                  <ProductVariants
                    product={product}
                    onNextConfig={onNextConfig}
                  />
                </>
              ) : (
                <h2 style={{color: '#ccc'}}>商品已售完</h2>
              )}
              <p>{product.description}</p>

              <Tabs activeKey={activeSummaryTab} onChange={setActiveSummaryTab}>
                <Tabs.TabPane tab="介紹" key="intro">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: mapLineBreak(product.intro),
                    }}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="規格" key="spec">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: mapLineBreak(product.spec),
                    }}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="備註" key="remark">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: mapLineBreak(product.remark),
                    }}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Summary>

            <div className="bottom-bar">
              <div className="center">
                <Button
                  size="large"
                  type="primary"
                  onClick={addToCart}
                  disabled={!isOnSale}>
                  <div>{addToCartLabel}</div>
                  <div>${currPrice || product.price}</div>
                </Button>
              </div>
            </div>
          </TopSection>

          {article && (
            <ArticlePreview dangerouslySetInnerHTML={{__html: article.html}} />
          )}
        </div>
      )}
    </StyledProductDetailModal>
  );
};

const StyledProductDetailModal = styled.div`
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  transition: all 500ms ease;
  visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
  opacity: ${({visible}) => (visible ? 1 : 0)};
  transform: ${({visible}) =>
    visible ? 'translateY(0)' : 'translateY(-100%)'};

  & > .cross {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 12;
  }

  & > .content {
    overflow: auto;
    height: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 20px;
  }
`;
const ArticlePreview = styled.div`
  & img {
    max-width: 100%;
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 80px;

  & .bottom-bar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    & > .center {
      margin: 0px auto;
      max-width: 500px;
      padding: 20px;
      & > button {
        height: 66px;
        width: 100%;
        font-size: 1.5rem;
        padding: 8px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
      }
      & > button:disabled {
        background-color: #ccc;
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`;

const Gallery = styled.div`
  width: ${(props) => props.size}px;
  aspect-ratio: 1;

  & > img:first-child {
    width: ${(props) => props.size}px;
    aspect-ratio: 1;
    object-fit: contain;
  }
`;

const Summary = styled.div`
  width: 100%;
  & > .title {
    display: flex;
    align-items: center;

    & > h2 {
      font-size: 1.8rem;
      line-height: 1.57;
    }

    & > .logi-label {
      margin-left: 8px;
      padding: 5px 12px;
      background-color: ${Constants.THEME_COLOR};
      color: white;
      font-size: 0.9rem;
      border-radius: 48px;
    }
  }

  & h3 {
    font-size: 21px;
  }

  & > p {
    margin: 20px 0;
    font-size: 17px;
  }

  flex: 1;
  flex-basis: 450px;
`;

const MiniImageList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MiniImageItem = styled.img`
  width: 64px;
  height: 64px;
  margin: 10px;
  border: 2px solid ${(props) => (props.selected ? '#ccc' : 'transparent')};
  border-radius: 4px;
  object-fit: contain;
  cursor: pointer;

  :first-child {
    margin-left: 0px;
  }
`;

export default ProductDetailModal;
