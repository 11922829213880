import React, {useState} from 'react';
import {Button} from 'antd';
import styled from 'styled-components';
import ProductGrid from './ProductGrid';
import ProductDetailModal from './ProductDetailModal';
import {useOutlet, getOutlet} from 'reconnect.js';
import QuickCart from '../../Components/QuickCart';
import * as AppActions from '../../AppActions';
import qs from 'query-string';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {isInPreview} from '../../Utils/PreviewUtil';

function selectCategory({queryParams, nextCat}) {
  const nextQueryParams = {...queryParams};

  if (!nextCat) {
    delete nextQueryParams.cat;
  } else {
    nextQueryParams.cat = nextCat;
  }

  let queryString = '';
  for (const key in nextQueryParams) {
    queryString += `${key}=${nextQueryParams[key]}&`;
  }
  queryString = queryString.slice(0, -1);

  AppActions.navigate(`/store/products?${queryString}`);
}

function ProductList(props) {
  const [product, setProduct] = useState(null);
  const [store, setStore] = useOutlet('store');
  const [products, setProducts] = useState([]);
  const [user] = useOutlet('user');
  const storeId = store?.store_id;

  React.useEffect(() => {
    async function fetchData() {
      if (!storeId) {
        return;
      }

      try {
        if (!isInPreview()) {
          setProducts(await AppActions.fetchCachedProduct(storeId));
        } else {
          const nextStoreConfig = await AppActions.fetchStoreConfig({
            store_id: storeId,
            useCache: false,
          });

          setStore({
            ...ApiUtil,
            ...nextStoreConfig,
          });

          setProducts(
            await JStorage.fetchDocuments(
              'product',
              {
                store_id: storeId,
              },
              undefined,
              null,
            ),
          );
        }
      } catch (ex) {
        console.warn('[ProductList] fail to get data from cache', ex);
      }
    }

    fetchData();
  }, [storeId, setStore]);

  if (!store) {
    return null;
  }

  const queryParams = qs.parse(props.location?.search);
  const selectedCat = queryParams.cat || '';

  const cats = [
    {name: '', display: '全部'},
    ...(store.product_category?.categories || []),
  ];

  const filteredProducts = products.filter((product) => {
    if (selectedCat === '') {
      return true;
    }

    return (product.labels || []).indexOf(selectedCat) > -1;
  });

  let bindEnabled = store?.config?.line_login_enabled;
  let showBindStore = false;

  if (bindEnabled) {
    if (user && user.data) {
      if (!user.data.store) {
        showBindStore = true;
      } else if (!user.data?.store[storeId]) {
        showBindStore = true;
      }
    }
  }

  return (
    <Wrapper>
      <div className="main-content">
        <div className="store-info">
          <div className="content">
            {store.landing?.hero_banner?.images[0]?.expected_url && (
              <img
                src={store.landing?.hero_banner?.images[0]?.expected_url}
                alt="hero banner"
              />
            )}

            <h2>{store.config?.store_title}</h2>
            <p>{store.config?.store_description}</p>
          </div>
        </div>

        <div className="store-category">
          {cats.map((cat, idx) => (
            <div key={idx}>
              <Button
                type={selectedCat === cat.name ? 'primary' : 'text'}
                style={{marginLeft: idx === 0 ? 0 : 8}}
                onClick={() =>
                  selectCategory({nextCat: cat.name, queryParams})
                }>
                {cat.display}
              </Button>
            </div>
          ))}
        </div>

        <div className="product-list">
          <ProductGrid
            products={filteredProducts}
            prefixPath={'/products'}
            onItemClick={(item) => setProduct(item)}
          />
        </div>
      </div>

      <ProductDetailModal
        product={product}
        hideModal={() => setProduct(null)}
      />

      <QuickCart />

      {showBindStore && (
        <div className="bind-line-store">
          <div className="content">
            <div>{`立即最佳化您的體驗-連結推播以便收到訂單更新！`}</div>
            <Button
              type="primary"
              onClick={async () => {
                AppActions.setLoading(true);
                await AppActions.delay(200);
                AppActions.bindStoreNotification(storeId);
              }}>
              連結{store.config?.store_title || ''}
            </Button>
          </div>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #eee;
  min-height: 100vh;
  padding: 80px 0;

  & > .main-content {
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
  }

  & .store-info {
    max-width: 600px;
    margin: 0 auto;

    & > .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      & img {
        display: block;
        width: 100%;
        min-height: 200px;
        object-fit: cover;
        background-color: #ccc;
        border: 6px solid white;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
      }

      & h2 {
        font-size: 1.5rem;
        font-weight: 200;
        line-height: 1.5;
        margin: 8px;
      }

      & p {
        margin-bottom: 40px;
        padding: 6px;
        text-align: center;
      }
    }
  }

  & .store-category {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 20px;
  }

  // force change the sdk's internal padding-top
  & .store-category + div {
    padding: 0;
  }

  & > .bind-line-store {
    padding: 8px 20px;
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

    & > .content {
      max-width: 500px;
      margin: 0 auto;
      display: flex;
      align-items: center;

      & > :first-child {
        flex: 1;
        margin-right: 8px;
      }
    }
  }
`;

export default ProductList;
